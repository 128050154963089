import React from 'react'
import { useTranslation } from 'react-i18next'

import { Controller } from 'react-hook-form'
import { useGetters } from '../../model'
import { OrderFoodOnlineSidebarProgramBlock } from '../../features/order-food-online/order-food-online-sidebar-program-block'
import { OrderFoodOnlineSidebarCalculationBlock } from '../../features/order-food-online/order-food-online-sidebar-calculation-block'
import {
  Divider,
  OrderFoodContactSidebarCalculationKcalItem,
  OrderFoodContactSidebarCalculationPriceItem,
  OrderFoodOnlineSidebarOrderContactInformationButtonItem,
  OrderFoodOnlineSidebarDPHPriceItem,
  OrderFoodOnlineSidebarPriceItemWithDPH,
  OrderFoodOnlineSidebarCalculationTransportPerDayItem,
  OrderFoodOnlineSidebarCalculationTransportTotalItem,
} from '../../features/order-food-online/order-food-online-sidebar-calculation-item'
import styles from './order-food-contact-information-sidebar.module.css'
import { OrderFoodOnlineSidebarPromoCodeItem } from '../../features/order-food-online/order-food-online-sidebar'

export const OrderFoodContactInformationSidebar = props => {
  const { methods } = props
  const getters = useGetters()
  const { t } = useTranslation()

  return (
    <Controller
      name="deliveryAddress"
      control={methods.control}
      render={() => (
        <div className={styles.sidebar}>
          <header>
            <h2>{t('forms.onlineOrderFormTitle4')}</h2>
            <p>Pokračujte prosím vyplněním kontaktních údajů.</p>
          </header>
          {/* <div className={styles.resultBlock}>
            <OrderFoodOnlineSidebarProgramBlock />
          </div> */}
          <div className={styles.resultBlock}>
            <OrderFoodOnlineSidebarCalculationBlock>
              <OrderFoodContactSidebarCalculationPriceItem size="lg" />
              <Divider />
              <OrderFoodContactSidebarCalculationKcalItem size="lg">
                {numberFormat(getters.getOrderFoodPriceDiscountByDays)}
              </OrderFoodContactSidebarCalculationKcalItem>
              {getters.isOrderFoodDeliveryFree ? null : (
                <>
                  <Divider />
                  <OrderFoodOnlineSidebarCalculationTransportPerDayItem size="sm" />
                  <Divider />
                  <OrderFoodOnlineSidebarCalculationTransportTotalItem size="sm" />
                </>
              )}
              {getters.isCompanyOrder && (
                <>
                  <Divider />
                  <OrderFoodOnlineSidebarDPHPriceItem size="sm" />
                  <Divider />
                  <OrderFoodOnlineSidebarPriceItemWithDPH size="sm" />
                </>
              )}
            </OrderFoodOnlineSidebarCalculationBlock>
          </div>
          <OrderFoodOnlineSidebarOrderContactInformationButtonItem />

          {/* <span className={styles.sidebarBackground} /> */}
          <OrderFoodOnlineSidebarPromoCodeItem />
        </div>
      )}
    />
  )
}

const numberFormat = value => {
  if (!value) return value
  return (value || '')?.toLocaleString().replace(',', ' ')
}
