import React from 'react'

import { useTranslation } from 'react-i18next'
import styles from './styles.module.css'

export const Warning = (props) => {
    const { status, isWarningStatus, isHomePage } = props
    const { t } = useTranslation()

    if (isWarningStatus(status)) {
        return (
            <WarningText isHomePage={isHomePage} message={t('home.orderFoodOnline.step2.inputErrorMsg')} />
        )
    }

    return null
}

const WarningText = (props) => {
    const { message, isHomePage } = props
    return <span className={isHomePage ? styles.warningMessageHome : styles.warningMessage}>{message}</span>
}
