import React from 'react'

import styles from './styles.module.css'

export const Error = (props) => {
    const { message, status, isFailStatus, isSuccessStatus, isHomePage } = props

    if (isFailStatus(status)) {
        return <ErrorText isHomePage={isHomePage} message="Je nám líto! Zatím v této lokalitě nejsme." />
    }

    if (!isSuccessStatus(status) && message) {
        return <ErrorText isHomePage={isHomePage} message={message} />
    }

    return null
}

const ErrorText = (props) => {
    const { message, isHomePage } = props
    return <span className={isHomePage ? styles.errorMessageHome : styles.errorMessage}>{message}</span>
}
