import React from 'react'
import cx from 'classnames'

import { Container } from '../../../../../components/container'
import { Steps } from '../steps'
import styles from './order-food-contact-information-template.module.css'
import { LogoIcon } from '../icons'

export const OrderFoodContactInformationTemplate = props => {
  const { sidebar, price, back, children } = props

  return (
    <section className={styles.section}>
      <div className={cx(styles.backBlock, styles.mobile)}>
        {back}
      </div>
      <Steps step={2} />

      <Container className={cx('text-center', styles.container)}>
        <div className={styles.formWrapContainer}>
          <div className={styles.formInnerContainer}>
            <div className={styles.grid}>
              <div className={styles.selectColumn}>
                <span className={styles.sidebarBackground} />
                <div className={cx(styles.backBlock, styles.desktop)}>
                  {back}
                </div>
                <div>{children}</div>
                <div className={styles.mobile}>
                  <div className={styles.priceBlock}>{price}</div>
                </div>
              </div>
              <div className={styles.resultColumn}>
                <div className={cx(styles.backBlock, styles.hidden)}>
                  {back}
                </div>
                <div className={styles.desktop}>{sidebar}</div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}
