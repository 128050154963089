import React from 'react'
import styles from './button.module.css'
export const Button = (props) => {
    const { onClick, disabled, children } = props
    return (
        <span className={styles.buttonBlock}>
            <button
                type="button"
                onClick={onClick}
                className={styles.button}
                disabled={disabled}
            >
                {children}
            </button>
        </span>
    )
}