import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './city-input.module.css'
import { useCallback } from 'react'
import Select from 'react-select'
import { Error } from '../../../components/error'

const CityStatus = {
    DEFAULT: 'default',
    SUCCESS: 'success',
    WARNING: 'warning',
    FAIL: 'fail',
}

const customStyles = {
    control: (provided) => ({
        ...provided,
        borderRadius: 0,
        height: '100%',
        borderRadius: '40px',
        border: 'none',
        outline: 'none',
        paddingLeft: '12px',
        whiteSpace: 'nowrap',
        background: '#F8F8F8',
    }),
}

export const CityInput = (props) => {
    const { defaultValue, error, fullWidth, onChange, changeCityValue } = props
    const { t } = useTranslation()

    const [cityStatus, setCityStatus] = useState(CityStatus.DEFAULT)
    const [cities, setCities] = useState([])
    const getCities = useCallback(async () => {
        const response = await fetch(`${process.env.GATSBY_APP_API_URL}/v2/city`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        })
        const citiesResp = await response.json()
        setCities(citiesResp);
    }, [])

    useEffect(() => {
        getCities()
    }, [getCities])

    const handleChange = (event) => {
        if (event.value) {
            onChange(event, true)
            changeCityValue(event?.label)
            setCityStatus(CityStatus.SUCCESS)
        } else {
            const isBlurEvent = event?.type === 'blur'
            setCityStatus(isBlurEvent ? CityStatus.FAIL : CityStatus.DEFAULT)
        }
    }

    return (
        <div
            className={cx(styles.city, {
                [styles.success]: isSuccessStatus(cityStatus),
                [styles.fail]: isFailStatus(cityStatus),
                [styles.warning]: isWarningStatus(cityStatus),
                [styles.fail]: !isSuccessStatus(cityStatus) && error,
                [styles.fullWidth]: fullWidth,
            })}
        >
            <span className={styles.inputBlock}>
                <div className={styles.block}>
                    <Select
                        options={cities?.map((city) => ({
                            value: city.id,
                            label: city.name,
                            code: city.code
                        }))}
                        isSearchable={false}
                        placeholder={defaultValue}
                        className={cx(styles.select, {
                            [styles.inputSuccess]: isSuccessStatus(cityStatus),
                            [styles.inputWarning]: isWarningStatus(cityStatus),
                            [styles.inputError]: isFailStatus(cityStatus),
                        })}
                        styles={customStyles}
                        onChange={handleChange}
                    />
                </div>
            </span>
            <Error
                status={cityStatus}
                message={error}
                isFailStatus={isFailStatus}
                isSuccessStatus={isSuccessStatus}
            />
        </div>
    )
}


const isWarningStatus = (status) => {
    return CityStatus.WARNING === status
}

const isSuccessStatus = (status) => {
    return CityStatus.SUCCESS === status
}

const isFailStatus = (status) => {
    return CityStatus.FAIL === status
}
