import React, { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import {
  isLocaleStorageEmpty,
  useLocalStorage,
} from '../../../../../shared/lib/local-storage'
import { AddressInput } from '../../../../form/address-input'
import { CityInput } from '../../../../form/city-input'
import {
  OrderStorageType,
  OrderFoodOnlineType,
  useSetters,
  useOrderFoodContext,
} from '../../model'
import {
  ErrorMessage,
  AddressErrorMessage,
  EmailErrorMessage,
  EmailErrorRequiredMessage,
  PhoneErrorMessage,
  ZIPErrorMessage,
  ICOErrorMessage,
  DICErrorMessage,
  OrganizationNameErrorMessage,
  FakturaErrorMessage,
  NameErrorMessage,
  CityErrorMessage,
} from '../../../../../components/forms/forms-error-messages'
import { PersPayInfo, UserIcon } from '../../components/icons'
import { Toggle } from '../../components/form/toggle'
import { Form, FieldGroup, Label } from '../../components/form'
import { Input, PhoneInput } from '../../components/hooks'
import { BackButton } from '../../components/back-button'
import { OrderFoodContactInformationTemplate } from '../../components/templates/order-food-contact-information-template'
import { OrderFoodContactInformationSidebar } from './order-food-contact-information-sidebar'
import { OrderFoodContactInformationPrice } from './order-food-contact-information-price'
import styles from '../../components/templates/order-food-contact-information-template.module.css'
import { useGetters } from '../../model'
import { OrderFoodDeliveryType } from '../../model/order-food-delivery-type'
import { SearchAddressInput } from '../../../../search-addres'

const OrderFoodContactInformationView = props => {
  const { methods, form, onSubmit } = props
  const { t } = useTranslation()
  const getters = useGetters()
  const setters = useSetters()
  const [cityValue, setCityValue] = useState('')

  const [orderStorage, setOrderStorage] = useLocalStorage(
    OrderStorageType.orderFoodOnline,
    {}
  )

  const [contactStorage, setContactStorage] = useLocalStorage(
    OrderStorageType.orderFoodContact,
    {}
  )

  const isCompanyOrder = methods.watch('isCompanyOrder', false)

  const handleToggle = value => {
    methods.setValue('isCompanyOrder', value)
    setters.setIsCompanyOrder(value)
    setOrderStorage({
      ...orderStorage,
      [OrderFoodOnlineType.IsCompanyOrder]: value,
    })
  }

  const changeCityValue = (value) => setCityValue(value)

  const handleAddress = value => {
    methods.setValue('deliveryAddress', value)
    methods.setValue('deliveryAddressIsPointInPolygon', value?.isPointInPolygon)
    methods.setValue(
      'deliveryPrice',
      value?.isPointInPolygon === false ? 50 : 0
    )
    setters.setOrderFoodDeliveryPrice(
      value?.isPointInPolygon === false ? 50 : 0
    )

    const adress = value.label

    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: `online_order_adress_check`,
        pageURL: `${window.location.pathname}/adress_check=${value?.isPointInPolygon}&&adress=${adress}`,
        adress_check:value?.isPointInPolygon,
        adress
      })

  }

  const handleCity = (value, isCity) => {
    methods.setValue('city', value)
    methods.setValue('isCity', isCity)

    const city = value.label
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'online_order_city',
        pageURL: `${window.location.pathname}/city=${city}`,
        city:value.label
      })

  }

  const handleClearAddress = () => {
    methods.setValue('deliveryAddress', '')
    methods.setValue('deliveryAddressIsPointInPolygon', false)
  }

  const handleClearCity = () => {
    methods.setValue('city', '')
    methods.setValue('isCity', false)
  }

  const handleBack = e => {
    setOrderStorage({
      ...orderStorage,
      [OrderFoodDeliveryType.Price]: null,
    })
    setContactStorage({
      ...contactStorage,
      deliveryPrice: null,
      deliveryAddressIsPointInPolygon: null,
    })
  }
  const isEn = document.location.pathname.includes("/en")
  const back = isEn ? '/en/order-food-online' : '/order-food-online'

  return (
    <Form onSubmit={onSubmit}>
      <OrderFoodContactInformationTemplate
        back={
          <BackButton to={back} onClick={handleBack}>
            {t('home.orderFoodOnline.backBtn')}
          </BackButton>
        }
        sidebar={<OrderFoodContactInformationSidebar methods={methods} />}
        price={<OrderFoodContactInformationPrice />}
      >
        <div className={styles.content}>
          <header className={styles.desktop}>
            <h2>{t('home.orderFoodOnline.steps.step2')}</h2>
            <p>{t('home.orderFoodOnline.step2.subtitle')}</p>
          </header>

          <div className={styles.selectBlock}>
            <header className={styles.header}>
              <h3>
                <UserIcon />
                <span>{t('forms.onlineOrderFormTitle2')}</span>
              </h3>
            </header>
            <div className={styles.formContainer}>
              <div className={styles.formBlock}>
                <FieldGroup>
                  <Label required>{t('forms.onlineOrderFormNamesLabel')}</Label>
                  <Input
                    placeholder={t('forms.onlineOrderFormNamesLabel')}
                    name="name"
                    error={form.errors?.name?.message}
                  />
                </FieldGroup>
              </div>
              <div className={styles.formBlock}>
                <FieldGroup>
                  <Label required>
                    {t('forms.onlineOrderFormNumberLabel')}
                  </Label>
                  <PhoneInput
                    placeholder={t('forms.onlineOrderFormNumberLabel')}
                    name="phone"
                    value="+420"
                    error={form.errors?.phone?.message}
                  />
                </FieldGroup>
              </div>
              <div className={styles.formBlock}>
                <FieldGroup>
                  <Label required>E-mail</Label>
                  <Input
                    placeholder={t('forms.onlineOrderFormEmailPlaceholder')}
                    name="email"
                    error={form.errors?.email?.message}
                  />
                </FieldGroup>
              </div>
            </div>

            <div className={styles.formContainer}>
              <div className={styles.formBlock}>
                <div className={styles.formBlock}>
                  <Toggle
                    onClick={handleToggle}
                    toggled={!!getters.isCompanyOrder}
                  >
                    {t('forms.onlineOrderFormCompanyLabel')}
                  </Toggle>
                </div>
              </div>
            </div>

            {isCompanyOrder && (
              <>
                <div className={styles.space} />
                <div>
                  <header className={styles.header}>
                    <h3>
                      <PersPayInfo />
                      <span>
                        {t('home.orderFoodOnline.step2.perCompanyTitle')}
                      </span>
                    </h3>
                    {/* <p>
                      Stačí vyplnit IČO společnosti a všechny ostatní údaje
                      předvyplníme za vás.
                    </p> */}
                  </header>
                  <div className={styles.formContainer}>
                    <div className={styles.formBlock}>
                      <FieldGroup>
                        <Label required>
                          {t('home.orderFoodOnline.step2.registrationNumber')}
                        </Label>
                        <Input
                          placeholder={t(
                            'home.orderFoodOnline.step2.registrationNumber'
                          )}
                          name="companyOrderIco"
                          error={form.errors?.companyOrderIco?.message}
                        />
                      </FieldGroup>
                    </div>
                    <div className={styles.formBlock}>
                      <FieldGroup>
                        <Label required>
                          {t('home.orderFoodOnline.step2.TAX')}
                        </Label>
                        <Input
                          placeholder={t('home.orderFoodOnline.step2.TAX')}
                          name="companyOrderDic"
                          error={form.errors?.companyOrderDic?.message}
                        />
                      </FieldGroup>
                    </div>
                    <div className={styles.formBlock}>
                      <FieldGroup>
                        <Label required>
                          {t('home.orderFoodOnline.step2.companyName')}
                        </Label>
                        <Input
                          placeholder={t(
                            'home.orderFoodOnline.step2.companyName'
                          )}
                          name="companyOrderCompany"
                          error={form.errors?.companyOrderCompany?.message}
                        />
                      </FieldGroup>
                    </div>
                    <div className={styles.formBlock}>
                      <FieldGroup>
                        <Label required>
                          {t('home.orderFoodOnline.step2.companyAddress')}
                        </Label>
                        <Input
                          placeholder={t(
                            'home.orderFoodOnline.step2.companyAddress'
                          )}
                          name="companyOrderAddress"
                          error={form.errors?.companyOrderAddress?.message}
                        />
                      </FieldGroup>
                    </div>
                    <div className={styles.formBlock}>
                      <FieldGroup>
                        <Label required>
                          {t('home.orderFoodOnline.step2.postcode')}
                        </Label>
                        <Input
                          placeholder={t('home.orderFoodOnline.step2.postcode')}
                          name="companyOrderZip"
                          error={form.errors?.companyOrderZip?.message}
                        />
                      </FieldGroup>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={styles.selectBlock}>
            <header className={styles.header}>
              <h3>
                <PersPayInfo />
                <span>{t('home.orderFoodOnline.step2.deliveryAddress')}</span>
              </h3>
              <p>{t('home.orderFoodOnline.step2.inputDescription')}</p>
            </header>
            <div>
              <Label required>
                {t('home.orderFoodOnline.step2.city')}
              </Label>
              <CityInput
                defaultValue={t('home.orderFoodOnline.step2.inputCityPlaceholder')}
                name="city"
                initialValue={form?.defaultValues?.city || undefined}
                error={form.errors?.isCity?.message}
                fullWidth
                onChange={handleCity}
                onClear={handleClearCity}
                changeCityValue={changeCityValue}
              />
            </div>
            <div className={styles.searchAddress}>
              <Label required>
                {t('home.orderFoodOnline.step2.inputLabel')}
              </Label>
              <SearchAddressInput
                placeholder={t('home.orderFoodOnline.step2.inputPlaceholder')}
                name="deliveryAddress"
                initialValue={form?.defaultValues?.deliveryAddress || undefined}
                error={form.errors?.deliveryAddressIsPointInPolygon?.message}
                fullWidth
                onChange={handleAddress}
                onClear={handleClearAddress}
                cityValue={cityValue}

              />
            </div>
          </div>
        </div>
      </OrderFoodContactInformationTemplate>
    </Form>
  )
}

const initialState = values => ({
  name: values?.name || undefined,
  phone: values?.phone || undefined,
  email: values?.email || undefined,
  isCompanyOrder: values?.isCompanyOrder || false,
  companyOrderZip: values?.companyOrderZip || undefined,
  companyOrderIco: values?.companyOrderIco || undefined,
  companyOrderDic: values?.companyOrderDic || undefined,
  companyOrderCompany: values?.companyOrderCompany || undefined,
  companyOrderAddress: values?.companyOrderAddress || undefined,
  deliveryAddress: values?.deliveryAddress || undefined,
  deliveryAddressIsPointInPolygon: null,
  deliveryPrice: values?.deliveryPrice || null,
  city: values?.city || null,
  isCity: null
})

const schema = yup
  .object({
    name: yup.string().required(<NameErrorMessage />),
    phone: yup
      .string()
      .min(9, <PhoneErrorMessage />)
      .phone(),

    companyOrderZip: yup.string().when('isCompanyOrder', {
      is: value => value === true,
      then: yup.string().required(<ZIPErrorMessage />),
    }),
    companyOrderIco: yup.string().when('isCompanyOrder', {
      is: value => value === true,
      then: yup.string().required(<ICOErrorMessage />),
    }),
    companyOrderDic: yup.string().when('isCompanyOrder', {
      is: value => value === true,
      then: yup.string().required(<DICErrorMessage />),
    }),
    companyOrderCompany: yup.string().when('isCompanyOrder', {
      is: value => value === true,
      then: yup.string().required(<OrganizationNameErrorMessage />),
    }),
    companyOrderAddress: yup.string().when('isCompanyOrder', {
      is: value => value === true,
      then: yup.string().required(<FakturaErrorMessage />),
    }),

    email: yup
      .string()
      .email(<EmailErrorMessage />)
      .required(<EmailErrorRequiredMessage />),
    isCity: yup
      .bool()
      .oneOf([true, false], <CityErrorMessage />)
      .nullable(true),
    deliveryAddressIsPointInPolygon: yup
      .bool()
      .oneOf([true, false], <AddressErrorMessage />)
      .nullable(true),
  })
  .required()

export const OrderFoodContactInformation = () => {
  const setters = useSetters()

  const [orderStorage, setOrderStorage] = useLocalStorage(
    OrderStorageType.orderFoodOnline,
    {}
  )
  const [contactStorage, setContactStorage] = useLocalStorage(
    OrderStorageType.orderFoodContact,
    {}
  )

  useEffect(() => {
    if (!isLocaleStorageEmpty(orderStorage)) {
      setters.setState(orderStorage)
    }
    if (isLocaleStorageEmpty(orderStorage)) {
      window.location.href = '/#calculator'
    }
  }, [])

  const methods = useForm({
    defaultValues: initialState({ ...contactStorage, ...orderStorage }),
    resolver: yupResolver(schema),
  })
  const isEn = document.location.pathname.includes("/en")

  const handleSubmit = async data => {
    setContactStorage(data)
    setOrderStorage({
      ...orderStorage,
      [OrderFoodOnlineType.IsCompanyOrder]: data?.isCompanyOrder,
      [OrderFoodDeliveryType.Price]: data?.deliveryPrice,
    })
    window.location.href = isEn ? '/en/order-food-online/order-food-delivery' : '/order-food-online/order-food-delivery'
  }

  const userData = localStorage.getItem('order-food-contact-information');
  const data = JSON.parse(userData);

  const name = data?.name;
  const phone = data?.phone;
  const email = data?.email;
  const zip = data?.deliveryAddress?.components?.postcode ? data?.deliveryAddress?.components?.postcode : '';
  const city = data?.city?.label ? data?.city?.label : '';
  const state = 'CZ';

  let firstName = '';
  let lastName = '';

  if (name) {
    const nameParts = name.split(' ');
    firstName = nameParts[0];
    lastName = nameParts.slice(1).join(' ');
  }

  const user_data = {
    firstName,
    lastName,
    phone,
    email,
    zip,
    city,
    state
  };

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer &&
      window.dataLayer.push({
        event: 'step_2_contact_info',
        pageURL: window.location.pathname,
        user_data,
      });
  }, [])


  return (
    <FormProvider {...methods}>
      {orderStorage && (
        <OrderFoodContactInformationView
          form={methods.formState}
          methods={methods}
          onSubmit={methods.handleSubmit(handleSubmit)}
        />
      )}
    </FormProvider>
  )
}