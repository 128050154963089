import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { useGetters } from '../../model'
import { FemaleIcon, MaleIcon } from '../../components/icons'
import styles from './order-food-online-sidebar-program-block.module.css'

export const OrderFoodOnlineSidebarProgramBlock = () => {
  const getters = useGetters()

  return (
    <div className={cx(styles.resultBlock, styles.recruitmentProgramBlock)}>
      <div className={styles.programBlock}>
        <div className={styles.column}>
          <span className={styles.count}>
            {getters.getOrderFoodNumberOfPeople}x
          </span>
        </div>
        <div className={cx(styles.column, styles.fullWidth)}>
          <ProgramNameFactory />
          <MenuCount />
        </div>
        <div className={styles.column}>
          <GenderIcon />
        </div>
      </div>
    </div>
  )
}

const ProgramNameFactory = () => {
  const { t } = useTranslation()

  const getters = useGetters()

  if (getters.isOrderFoodProgramWeightLoss) {
    return (
      <span className={styles.title}>
        {t('home.orderFoodOnline.weightLossProgram')}
      </span>
    )
  }
  if (getters.isOrderFoodProgramWeightGain) {
    return (
      <span className={styles.title}>
        {t('home.orderFoodOnline.weightGainProgram')}
      </span>
    )
  }
  if (getters.isOrderFoodProgramMaintenance) {
    return (
      <span className={styles.title}>
        {t('home.orderFoodOnline.maintenanceProgram')}
      </span>
    )
  }
  if (getters.isOrderFoodProgramLunchAndDinner) {
    return (
      <span className={styles.title}>
        {t('home.orderFoodOnline.lunchAndDinnerProgram')}
      </span>
    )
  }
}

const MenuCount = () => {
  const { t } = useTranslation()

  const getters = useGetters()
  const meals = getters.getOrderFoodNumberOfMeals

  return (
    <p>
      {meals.length} {t('home.orderFoodOnline.courseMenu')}
    </p>
  )
}

const GenderIcon = () => {
  const getters = useGetters()

  if (getters.isOrderFoodCustomerGenderMale) {
    return (
      <span>
        <MaleIcon />
      </span>
    )
  }
  if (getters.isOrderFoodCustomerGenderFemale) {
    return (
      <span>
        <FemaleIcon />
      </span>
    )
  }
}
