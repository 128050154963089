import React from 'react'

import styles from './styles.module.css'
import { CheckRadioGreenOnIcon } from '../../features/pages/order-food-online/components/icons'

export const Success = (props) => {
    const { message, status, isSuccessStatus, isHomePage } = props

    if (isSuccessStatus(status)) {
        return <SuccessText message={message} isHomePage={isHomePage} />
    }

    return null
}

export const SuccessIcon = () => {
    return (
        <span className={styles.successIconBlock}>
            <CheckRadioGreenOnIcon />
        </span>
    )
}

const SuccessText = (props) => {
    const { message, isHomePage } = props
    return <span className={isHomePage ? styles.successMessageHome : styles.successMessage}>{message}</span>
}
