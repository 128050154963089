import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { useModalContext } from '../../../../../shared/lib/react-context-modal'
import { Button2 } from '../../../../../components/button2'
import { OrderFoodCallMeModal } from './order-food-call-me-modal'
import { OrderFoodOnlineSidebarProgramBlock } from './order-food-online-sidebar-program-block'
import { OrderFoodOnlineSidebarCalculationBlock } from './order-food-online-sidebar-calculation-block'
import {
  Divider,
  OrderFoodOnlineSidebarCalculationKcalItem,
  OrderFoodOnlineSidebarCalculationPriceItem,
} from './order-food-online-sidebar-calculation-item'
import styles from './order-food-online-sidebar.module.css'
import { useGetters } from '../../model'

export const OrderFoodOnlineSidebar = () => {
  return (
    <div className={styles.sidebar}>
      {/* <OrderFoodOnlineSidebarProgramBlock /> */}
      <OrderFoodOnlineSidebarCalculationBlock>
        <OrderFoodOnlineSidebarCalculationPriceItem />
        <Divider />
        <OrderFoodOnlineSidebarCalculationKcalItem />
      </OrderFoodOnlineSidebarCalculationBlock>
      <OrderFoodOnlineSidebarOrderOrderOnlineItem />
      <OrderFoodOnlineSidebarPromoCodeItem />
    </div>
  )
}

const OrderFoodOnlineSidebarOrderOrderOnlineItem = () => {
  const { t } = useTranslation()
  const { showModal } = useModalContext()

  const handleCallMe = async () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer && window.dataLayer.push({
      event: `form_view_callback`,
      pageURL: window.location.pathname
    });
    showModal(OrderFoodCallMeModal)
    localStorage.setItem('formName', "online-order-food")

  }

  return (
    <>
      <div className={cx(styles.resultBlock, styles.orderBlock)}>
        <div className={styles.buttonBlock}>
          <Button2
            size="sm"
            variant="outline"
            color="secondary"
            fullWidth
            onClick={handleCallMe}
          >
            {t('home.orderFoodOnline.callMe')}
          </Button2>
        </div>
        <div className={styles.buttonBlock}>
          <Button2 size="sm" color="secondary" buttonType="submit" fullWidth>
            {t('home.orderFoodOnline.orderOnline')}
          </Button2>
        </div>
      </div>
    </>
  )
}

export const OrderFoodOnlineSidebarPromoCodeItem = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.promoCode}>
      <p>{t('home.orderFoodOnline.do you have a promo code?')}</p>
      <p>
        {t('home.orderFoodOnline.you can fill it out at the end of the order')}
      </p>
    </div>
  )
}
