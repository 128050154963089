import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import styles from './search-address-input.module.css'
import { checkDeliveryAddress } from '../../shared/api/client'
import { Warning } from '../../components/warning'
import { Success, SuccessIcon } from '../../components/success'
import { Error } from '../../components/error'
import { Input } from '../pages/order-food-online/components/form'
import { Button } from '../pages/order-food-online/components/form/button'
import { ButtonTransparent } from '../pages/order-food-online/components/form/buttonTransparent'

const AddressStatus = {
    DEFAULT: 'default',
    SUCCESS: 'success',
    WARNING: 'warning',
    FAIL: 'fail',
}

export const SearchAddressInput = (props) => {
    const { placeholder, error, fullWidth, onChange, cityValue } = props
    const { t } = useTranslation()

    const [addressValue, setAddressValue] = useState('')
    const [addressStatus, setAddressStatus] = useState(AddressStatus.DEFAULT)
    const [isExistValue, setIsExistValue] = useState(false)
    const [postCode, setPostCode] = useState('')
    const [formattedAddress, setFormattedAddress] = useState('')
    const customCityValue = cityValue === 'Praha a okolní města'
    const textWithoutSpace = addressValue.trim().replace(/(\s*,\s*)/g, ',')
    const isAddressHostivica = textWithoutSpace.toLowerCase().includes('hostivice')
    const cityValueText = isAddressHostivica ? '' : cityValue
    const addressLabel = isAddressHostivica ? `${textWithoutSpace}, Czechia` : `${addressValue}, ${customCityValue ? 'Prague' : cityValue}`
    const addressValidation = async (address) => {
        try {
            const body = await checkDeliveryAddress(address)
            const isAddDeliveryFee = body?.result?.isPointInPolygon
            const addressValueObject = body.result.address
            if (isAddDeliveryFee === true) {
                setAddressStatus(AddressStatus.SUCCESS)
                setPostCode(addressValueObject.components.postcode)
                setFormattedAddress(addressValueObject.components.formatted)
                onChange({
                    ...addressValueObject,
                    ...body?.result,
                    label: addressValueObject.components.formatted,
                    isPointInPolygon: true,
                    isSuccess: true,
                    isWarning: false,
                    isError: false,
                })
                setIsExistValue(true)
            } else if (isAddDeliveryFee === false) {
                setAddressStatus(AddressStatus.WARNING)
                setPostCode(addressValueObject.components.postcode)
                setFormattedAddress(addressValueObject.components.formatted)
                onChange({
                    ...addressValueObject,
                    ...body?.result,
                    isPointInPolygon: false,
                    label: addressValueObject.components.formatted,
                    isSuccess: false,
                    isWarning: true,
                    isError: false,
                })
                setIsExistValue(true)

            } else if (isAddDeliveryFee === undefined || isAddDeliveryFee === null) {
                setPostCode('')
                setFormattedAddress('')
                setAddressStatus(AddressStatus.FAIL)
                onChange({
                    ...addressValueObject,
                    ...body?.result,
                    isPointInPolygon: null,
                    label: addressLabel,
                    isSuccess: false,
                    isWarning: false,
                    isError: true,
                })
                setIsExistValue(true)

            }
        } catch (error) {
            onChange({
                isPointInPolygon: null,
                label: addressLabel,
                isSuccess: false,
                isWarning: false,
                isError: true,
            })
            setFormattedAddress('')
            setIsExistValue(true)
            setAddressStatus(AddressStatus.FAIL)
            setPostCode('')
        }
    }

    useEffect(() => {
        if (cityValue && addressValue) {
            addressValidation(addressLabel)
        }

    }, [cityValue])

    const handleChange = (event) => {
        if (event.target.value) {
            setAddressValue(
                event.target.value
            )
        } else {
            setAddressValue(
                ''
            )
        }
        setAddressStatus(AddressStatus.DEFAULT)

    }

    const handleCancelClick = () => {
        setAddressValue('')
        setAddressStatus(AddressStatus.DEFAULT)
        onChange({
            isPointInPolygon: null,
            label: '',
        })
        setIsExistValue(false)

    }

    return (
        <div
            className={cx(styles.address, {
                [styles.success]: isSuccessStatus(addressStatus),
                [styles.fail]: isFailStatus(addressStatus) || (!isSuccessStatus(addressStatus) && error),
                [styles.warning]: isWarningStatus(addressStatus),
                [styles.fullWidth]: fullWidth,
            })}
        >
            <span className={styles.input}>
                {
                    isExistValue ? <div className={cx(styles.customInput, {
                        [styles.success]: isSuccessStatus(addressStatus),
                        [styles.fail]: isFailStatus(addressStatus) || (!isSuccessStatus(addressStatus) && error),
                        [styles.warning]: isWarningStatus(addressStatus),
                        [styles.fullWidth]: fullWidth,
                    })} >            {isSuccessStatus(addressStatus) && <SuccessIcon />}
                    </div> : <Input
                        placeholder={placeholder}
                        onChange={handleChange}
                        error={isFailStatus(addressStatus) ? error : undefined}

                    />
                }
            </span>
            {!isDefaultStatus(addressStatus) && (
                <ButtonTransparent code={postCode} formattedAddress={formattedAddress} addressValue={addressValue} city={cityValueText} postCode={postCode} onClick={handleCancelClick} style={{
                    color: isSuccessStatus(addressStatus) ? '#44c789' :
                        isWarningStatus(addressStatus) ? '#ffcc00' :
                            (isFailStatus(addressStatus) || (!isSuccessStatus(addressStatus) && error)) ? '#980000' :
                                '#87A5C0'
                }} />

            )}
            {isDefaultStatus(addressStatus) && (
                <Button onClick={() => { addressValidation(addressLabel) }} disabled={!addressValue || !cityValue}>
                    {t('home.orderFoodOnline.step2.confirmAddress')}
                </Button>
            )}
            {((isFailStatus(addressStatus) || (!isWarningStatus(addressStatus) && error))) && <Error
                status={addressStatus}
                isFailStatus={isFailStatus}
                isSuccessStatus={isSuccessStatus}
                message={error}
            />}
            <Success
                status={addressStatus}
                message={t('home.orderFoodOnline.step2.inputSuccesMsg')}
                isSuccessStatus={isSuccessStatus}
            />
            <Warning
                status={addressStatus}
                message={t('home.orderFoodOnline.step2.inputErrorMsg')}
                isWarningStatus={isWarningStatus}
            />
        </div>
    )
}

const isWarningStatus = (status) => {
    return AddressStatus.WARNING === status
}

const isSuccessStatus = (status) => {
    return AddressStatus.SUCCESS === status
}

const isFailStatus = (status) => {
    return AddressStatus.FAIL === status
}

const isDefaultStatus = (status) => {
    return AddressStatus.DEFAULT === status
}

