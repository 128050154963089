import React, { useEffect, useState } from 'react'
import styles from './toggle.module.css'

export const Toggle = ({ label, toggled, children, onClick }) => {
  const [isToggled, toggle] = useState()

  const callback = () => {
    toggle(!isToggled)
    if (onClick) {
      onClick(!isToggled)
    }
  }

  useEffect(() => {
    toggle(toggled)
  }, [toggled])

  return (
    <div className={styles.block}>
      <span className={styles.text}>{children}</span>
      <label className={styles.label}>
        <input
          type="checkbox"
          defaultChecked={isToggled}
          onClick={callback}
          className={styles.input}
        />
        <span className={styles.span} />
        <strong className={styles.strong}>{label}</strong>
      </label>
    </div>
  )
}
